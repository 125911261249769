<template>
    <div>
        <custom-header description="List your project, product or website on the main page" title="How to advertise"/>

        <div class="questions container">
            <p>
                <span class="bold">Q1:</span> <span class="semibold">What do you mean by "advertise"?</span><br>
                <span class="bold">A:</span> You probably noticed the two "Your Project/Tool" banners on the homepage,
                you now have the opportunity to advertise for your tool/project/anything there.
            </p>
            <p>
                <span class="bold">Q2:</span> <span class="semibold">That sounds great, how can I get my project up there?</span><br>
                <span class="bold">A:</span> Pretty easy, just visit my patreon page and select the "Sponsor" tier, it's
                only 5 Euros. Don't forget to send the image and texts you want advertised afterwards.
            </p>
            <p>
                <span class="bold">Q3:</span> <span class="semibold">How long will my entry stay on the page?</span><br>
                <span class="bold">A:</span> As long as you are a patron with the "Sponser" tier!
            </p>
            <p>
                <span class="bold">Q4:</span> <span class="semibold">I am ready to go to patreon, what's your creator url?</span><br>
                <span class="bold">A:</span> It's <a :href="this.$config.other_links.patreon" decorated target="_blank">{{
                    this.$config.other_links.patreon
                }}</a> :)
            </p>
            <p>
                <span class="bold">Q5:</span> <span class="semibold">Last question, am I allowed to advertise NSFW content?</span><br>
                <span class="bold">A:</span> No, that's forbidden.
            </p>
        </div>
    </div>
</template>

<script>
import CustomHeader from "@/components/templates/CustomHeader";

export default {
    name: "Advertise",
    metaInfo: {
        title: 'Your Ad/Tool',
        meta: [
            {property: 'og:title', content: 'Your Ad/Tool'},
            {property: 'og:site_name', content: 'strukteon.net'},
            {property: 'og:type', content: 'website'},
            {property: 'og:description', content: 'Put your own project or tool on the homepage of strukteon.net'}
        ]
    },
    components: {
        CustomHeader
    }
}
</script>

<style scoped>
.questions {
    color: rgba(255, 255, 255, .9);
    padding-top: 3em;
    padding-bottom: 3em;
}

.bold {
    font-weight: bold;
    color: white;
}

.semibold {
    font-weight: 600;
    color: white;
}
</style>